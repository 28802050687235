.container {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Remove fixed height so the container can adjust to content */
  min-height: 30px;
  -webkit-box-shadow: -4px -6px 9px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -4px -6px 9px -7px rgba(0, 0, 0, 0.75);
  box-shadow: -4px -6px 9px -7px rgba(0, 0, 0, 0.12);

  .inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;

    .company_logo {
      width: 120px;
      height: auto;
    }
    .company_logos {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
    }

    p {
      margin: 0;
      /* Adjust font size if necessary */
      font-size: 16px;
    }
  }
}

/* Tablet responsiveness */
@media screen and (max-width: 768px) {
  .container {
    .inner {
      flex-direction: column;
      gap: 10px;
      padding: 10px 0;

      .company_logos {
        /* Center logos and allow them to wrap if needed */
        justify-content: center;
        flex-wrap: wrap;
      }

      p {
        font-size: 14px;
        text-align: center;
      }
    }
  }
}

/* Mobile responsiveness */
@media screen and (max-width: 480px) {
  .container {
    .inner {
      padding: 8px 0;

      .company_logo {
        width: 100px; /* Slightly reduce logo size on small screens */
      }

      p {
        font-size: 12px;
      }
    }
  }
}
