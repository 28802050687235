@import "../../index.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 3rem;
  padding-left: 5rem;
  padding-right: 5rem;

  // Desktop: Display the left and right sides in a row
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }

  .left_side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;

    // You may adjust image margins for desktop if needed
    // img {
    //   margin-top: 2rem;
    // }
  }
  .right_side {
    width: 50%;

    .form {
      display: flex;
      flex-direction: column;
      padding: 2rem;

      .form_names {
        display: flex;
        gap: 1rem;

        .firstname,
        .lastname {
          width: 50%;
        }
      }

      input,
      textarea {
        padding: 7px 13px;
        outline: none; /* This removes the blue outline */
        border: 1px solid rgb(201, 200, 200);
        border-radius: 8px;
        margin-top: 1rem;
        margin-bottom: 0.7rem;
        background-color: white !important;

        &:focus {
          border-color: rgb(150, 150, 150);
          box-shadow: none;
        }
      }

      textarea {
        resize: none;
        margin-top: 0.7rem;
        outline: none;
        border: 1px solid rgb(201, 200, 200);
        border-radius: 8px;
        padding: 7px 13px;
      }

      .button {
        background-color: $base-pink;
        border: 1px solid black;
        border-radius: 5px;
        padding: 8px;
        color: white;
        -webkit-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.253);
        -moz-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.161);
        box-shadow: 4px 3px 0px 1px rgb(0, 0, 0);
        font-size: 16px;
        font-weight: 500;
        margin-top: 1rem;
      }
    }
  }
}

/* Tablet Styles */
@media (max-width: 1024px) and (min-width: 768px) {
  .container {
    padding-left: 3rem;
    padding-right: 3rem;
    display: flex;

    > div {
      flex-direction: column; /* Stack elements on top of each other */
      gap: 2rem;
    }
    .right_side {
      width: 80%; /* Increase form width for tablet screens */
    }
  }
}

/* Mobile Styles */
@media (max-width: 767px) {
  .container {
    padding: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;

    > div {
      flex-direction: column; /* Stack left and right sides vertically */
      align-items: center;
    }
    .left_side {
      text-align: center; /* Center text on smaller screens */
      margin-bottom: 1.5rem;
    }
    .right_side {
      width: 100%; /* Form takes full width */

      .form {
        padding: 1rem;

        .form_names {
          flex-direction: column; /* Stack first and last name inputs */
          .firstname,
          .lastname {
            width: 100%;
          }

          @media (max-width: 767px) {
            gap: 0;
            width: 100%;
          }
        }

        input,
        textarea {
          font-size: 14px; /* Adjust font size if necessary */
        }

        .button {
          font-size: 14px;
          padding: 8px;
        }
      }
    }
  }
}

.sub_container {
  display: "flex";
  flex-direction: "row";
  align-items: "center";
  justify-content: "space-evenly";

  @media (max-width: 767px) {
    flex-direction: column;
  }
}
