@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
//VARIABLES
$base-pink: #ae407a;
$base-green: #32b4a2;
$base-golden: #ffb743;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
  // outline: 1px solid red;   //this is to check if any item is overflowing

  ::-webkit-scrollbar {
    display: none;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

button {
  cursor: pointer;
}
.clickable {
  cursor: pointer;
}
.contain_center {
  width: 80%;
  margin: 0 auto;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
