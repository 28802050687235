.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 500px;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .modalClose {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 20px;
      cursor: pointer;
    }

    .modalBody {
      text-align: center;

      h2 {
        font-size: 24px;
        margin-bottom: 10px;
      }

      p {
        margin: 10px 0;
        font-size: 16px;
      }
    }

    .modalActions {
      margin-top: 20px;
      display: flex;
      gap: 1rem;
      justify-content: center;
      .cancelBtn,
      .deleteBtn {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background 0.3s ease;

        &:hover {
          opacity: 0.9;
        }
      }

      .cancelBtn {
        background-color: #eaeaea;
      }

      .deleteBtn {
        background-color: #ff4d4f;
        color: white;
      }
    }
  }
}
