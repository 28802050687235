@import "../../index.scss";

.container {
  padding: 2rem;

  .swiper {
    width: 100%;
    height: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    position: relative;

    .swiperSlide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      .wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        border: 1px solid $base-pink;
        border-radius: 10px;
        text-align: left;
        aspect-ratio: 16/9;
        align-items: center; // Align items to the start of the container

        .internal {
          display: flex;
          flex-direction: row;
          gap: 1rem;
          justify-content: flex-start;
          height: fit-content;
          width: 100%;
          margin-left: auto;
          margin-right: auto;

          .left {
            display: flex;
            justify-content: flex-end;
            width: 30%;

            img {
              width: 60px;
              height: 60px;
              aspect-ratio: 1/1;
              border-radius: 50%;
            }
          }

          .right {
            width: 70%;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            justify-content: flex-start; // Align text to the top

            .name {
              font-size: 18px;
              font-weight: bold;
            }
            .position {
              color: grey;
              font-size: 14px;
            }
            .work {
              color: black;
              font-size: 14px;
              width: 90%;
            }
          }
        }
      }
    }
  }
}
