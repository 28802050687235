@import "../../index.scss";

.forgotPasswordContainer {
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(214, 214, 214, 0.1);
  position: relative;
  align-content: center;
  gap: 1rem;

  .forgotPasswordForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30vw;
    padding: 20px;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    background-color: #f7f6f6a6;
    box-shadow: 0 2px 4px rgba(214, 214, 214, 0.1);

    .inputContainer {
      margin-bottom: 15px;
      width: 100%;

      label {
        display: block;
        margin-bottom: 5px;
        font-weight: 500;
      }

      input {
        width: 95%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }

    .message {
      color: red;
      margin-bottom: 10px;
      text-align: center;
      width: 100%;
      overflow-wrap: break-word;
      word-wrap: break-word;
      white-space: normal;
    }

    .submitButton {
      padding: 10px;
      background-color: $base-pink;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
      -webkit-box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.73);
      -moz-box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.73);
      box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.9);

      &:hover {
        background-color: darken($color: $base-pink, $amount: 3%);
      }
    }
  }
}

/* Responsive styles */

/* For devices with a maximum width of 1024px (small laptops/tablets) */
@media (max-width: 1024px) {
  .forgotPasswordContainer {
    .forgotPasswordForm {
      width: 50vw;
    }
  }
}

/* For tablets with a maximum width of 768px */
@media (max-width: 768px) {
  .forgotPasswordContainer {
    padding: 10px;
    .forgotPasswordForm {
      width: 80vw;
      padding: 15px;
    }
  }
}

/* For mobile devices with a maximum width of 480px */
@media (max-width: 480px) {
  .forgotPasswordContainer {
    padding: 5px;
    .forgotPasswordForm {
      width: 90vw;
      padding: 10px;
    }
  }
}
