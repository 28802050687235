@import "../../index.scss";

.container {
  position: sticky;
  top: 0;
  width: 100%;
  display: grid;
  place-items: center;
  min-height: 70px;
  z-index: 1000;
  background-color: white;
  box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.144);

  .wrapper {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: row;
      padding: 10px 0;
    }
  }

  .left_side_wrapper {
    .company_logo {
      width: 120px;
      height: auto;

      @media (max-width: 768px) {
        width: 100px;
      }
    }
  }

  .right_side_Wrapper {
    display: flex;
    align-items: center;
    gap: 1.5rem;

    @media (max-width: 768px) {
      gap: 0.5rem;
    }

    // Desktop navigation links
    .desktopNav {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      @media (max-width: 768px) {
        display: none;
      }
    }

    // Hamburger icon appears only on mobile
    .hamburgerIcon {
      height: 24px;
      width: 24px;
      display: none;
      cursor: pointer;

      @media (max-width: 768px) {
        display: block;
      }
    }

    .auth_btn_contnainer {
      display: flex;
      gap: 1rem;

      .login_btn {
        border-radius: 4px;
        border: 1px solid black;
        padding: 8px 16px;
        box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.9);

        @media (max-width: 768px) {
          padding: 6px 12px;
          font-size: 12px;
        }
      }

      .signup_btn {
        border-radius: 4px;
        border: 1px solid black;
        background-color: $base-pink;
        padding: 8px 16px;
        color: white;
        box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.73);

        @media (max-width: 768px) {
          padding: 6px 12px;
          font-size: 12px;
        }
      }
    }
  }
}

.profileModal {
  position: absolute;
  top: 40px; // Adjust to position below the user icon
  right: 0;
  background-color: white;
  border: 2px solid #f8a1d1;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 16px;
  z-index: 1000;
  width: 250px;
  font-family: "Arial, sans-serif";

  @media (max-width: 768px) {
    width: 200px;
    padding: 12px;
  }

  .top_section {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 16px;
    position: relative;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;

      @media (max-width: 768px) {
        width: 40px;
        height: 40px;
      }
    }

    .name {
      font-weight: 600;
      font-size: 16px;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }

    .plan {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      color: $base-pink;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }

    .closeIcon {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      font-size: 18px;
      color: #666;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }

  .button_container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      padding: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #333;
      border: none;
      border-radius: 5px;
      background-color: transparent;
      cursor: pointer;
      text-align: left;

      &:hover {
        background-color: #f8f8f8;
      }

      @media (max-width: 768px) {
        padding: 8px;
        font-size: 12px;
      }
    }

    .signOut {
      color: #e03333;
    }
  }
}

.profile_img_wrapper {
  height: 40px;
  width: 40px;
  position: relative;
  border: 1px solid $base_pink;
  border-radius: 40px;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 35px;
    width: 35px;
  }
}

.language_selector {
  position: relative;
}

.language_modal {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  border: 2px solid #f8a1d1;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 16px;
  z-index: 1000;
  font-family: "Arial, sans-serif";

  @media (max-width: 768px) {
    top: 35px;
    padding: 12px;
    font-size: 12px;
  }
}

/* Mobile Menu Styles */
.mobileMenu {
  position: absolute;
  top: 70px;
  right: 0;
  width: 90%;
  background-color: white;
  border-top: 1px solid #ddd;
  z-index: 1500;
  padding: 1rem;

  .subscription_container {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .mobileMenuHeader {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    cursor: pointer;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 1rem;
      a {
        text-decoration: none;
        color: inherit;
        font-size: 16px;
      }
    }
  }
}

.subscription_container {
  display: flex;
  align-items: center;
  gap: 8px;
}
