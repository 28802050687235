@import "../../index.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.425);
  overflow: hidden;

  .modal_content {
    display: flex;
    background-color: white;
    width: 70%;
    height: auto;
    border-radius: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;

    @media (max-width: 768px) {
      border-radius: 20px;
      width: 90%;
    }
  }

  .left_side {
    flex: 1;
    background-color: $base-pink;
    padding: 1.8rem;

    .container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: left;
      align-items: start;
      position: relative;
      gap: 2rem;

      .text_container {
        color: white;
      }

      .tryitout_image {
        position: absolute;
        width: 100%;
        height: auto;
        bottom: -30%;

        @media screen and (max-width: 600px) {
          bottom: -10%;
        }
        @media screen and (min-width: 1601px) {
          width: 100%;
        }
        @media screen and (min-width: 2001px) {
          width: 80%;
        }
      }
    }
  }

  .right_side {
    position: relative;
    flex: 1;
    padding: 20px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    .right_side_container {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      width: 100%;
      max-width: 500px;
      justify-content: center;
      align-items: center;

      .form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 80%;
      }

      .input_container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .input {
          border: 1px solid rgb(224, 224, 224);
          padding: 5px;
          border-radius: 5px;
          width: 100%;
          background-color: #fff;

          // Ensure the input text field does not overflow its container
          input {
            width: 100%;
            box-sizing: border-box;
            border: none;
            outline: none;
            background-color: #fff;
          }
        }

        ::-webkit-input-placeholder {
          color: grey;
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }

        .password_input {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .button_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;

        .login_btn {
          background-color: $base-pink;
          width: 100%;
          color: white;
          height: 40px;
          border: none;
          outline: none;
          border-radius: 5px;
        }

        .google_btn {
          background-color: rgb(229, 228, 228);
          width: 100%;
          color: black;
          height: 40px;
          border: none;
          outline: none;
          border-radius: 5px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 1rem;
        }
      }

      // Wrap the "Don't Have An Account?" and "Sign Up" elements in a flex container.
      p {
        display: flex;
        flex-direction: row; // default is row on larger screens
        gap: 0.5rem;
        align-items: center;
      }

      .signup_text {
        color: #32b4a2;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    @media (max-width: 768px) {
      width: 90%;
    }
  }

  .close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    color: #000;
  }
}

.error_message {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}

/* Tablet view */
@media screen and (max-width: 1024px) {
  .modal_content {
    width: 80%;
  }
}

/* Mobile view */
@media screen and (max-width: 768px) {
  .signup_text_container {
    flex-direction: column;
    align-items: center;
  }

  .modal_content {
    width: 95%;
    flex-direction: column;
  }

  .left_side {
    display: none;
  }

  .right_side {
    flex: 1;
    padding: 20px;
    width: 100%;
  }

  .right_side_container {
    p {
      flex-direction: column;
      align-items: center;
    }
  }
}
