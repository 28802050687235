@import "../../index.scss";

.no-scroll {
  overflow: hidden;
}

.profileOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: auto;

  .profileContainer {
    background-color: white;
    border-radius: 12px;
    padding: 0 24px 24px;
    min-width: 65%;
    max-width: 90%;
    max-height: 80%;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow-y: auto; // Enable scrolling within the modal

    &::-webkit-scrollbar {
      display: none;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
      border-bottom: 1px solid rgb(230, 228, 228);
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 20;
      padding-top: 20px;
      padding-bottom: 20px;

      .left_side {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .profileImageContainer {
          position: relative;
          display: flex;
          align-items: center;

          img.profileImage {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
          }

          label.uploadButton {
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            padding: 5px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;

            &:hover {
              background-color: rgba(0, 0, 0, 0.7);
            }
          }

          input[type="file"] {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
          }
        }

        h2 {
          font-size: 24px;
          font-weight: 600;
          color: #333;
        }
      }

      .closeIcon {
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
        color: #888;

        &:hover {
          color: #000;
        }
      }
      @media (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .profileContent {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .top_container {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 1.5rem;
        align-items: stretch;

        .userInfo {
          display: flex;
          align-items: center;
          border: 1px solid $base-pink;
          border-radius: 10px;
          padding: 1rem;
          text-transform: capitalize;

          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin-right: 16px;
          }

          div {
            .name {
              font-weight: 600;
              font-size: 18px;
              color: #333;
            }

            .joinDate {
              font-size: 14px;
              color: #888;
            }
          }
        }

        .subscribedPlanInfo {
          display: flex;
          align-items: center;
          border: 1px solid $base-pink;
          border-radius: 10px;
          padding: 1rem;
          text-transform: capitalize;
          flex: 1;
          justify-content: space-between;

          .left_side {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            flex: 1;
            margin-right: 2rem;

            .left_side_wrapper {
              display: flex;
              align-items: center;
              gap: 1rem;
              img {
                height: 20px;
                width: 20px;
              }
            }
            @media (max-width: 768px) {
              margin-right: 0px;
            }
          }

          .right_side {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .upgrade_plan {
              font-weight: 600;
              color: white;
              background-color: $base-pink;
              padding: 0.5rem 1rem;
              border-radius: 5px;
              cursor: pointer;
              border: 1px solid black;
              outline: none;
              -webkit-box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.73);
              -moz-box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.73);
              box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.9);

              &:hover {
                /* Optional hover styles */
              }
            }

            .cancel_plan {
              font-weight: 600;
              text-align: center;
              color: white;
              background-color: rgb(214, 97, 97);
              padding: 0.5rem 1rem;
              border-radius: 5px;
              cursor: pointer;
              border: 1px solid black;
              outline: none;
              -webkit-box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.73);
              -moz-box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.73);
              box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.9);

              &:hover {
                /* Optional hover styles */
              }
            }
          }
        }

        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
        }
      }

      .personalInfo {
        h3 {
          font-size: 16px;
          color: $base-pink;
          margin-bottom: 16px;
        }

        .infoGrid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 16px;
          margin-bottom: 2rem;

          div {
            .label {
              font-size: 14px;
              color: #888;
              margin-bottom: 4px;
              display: block;
            }

            .editable {
              display: flex;
              align-items: center;
              gap: 5px;

              .editableInput {
                border: none;
                outline: none;
                background: none;
                font-size: 14px;
                color: #333;
                padding: 0 2px;
                transition: border-bottom 0.3s ease;

                &.underline {
                  border-bottom: 1px solid #000;
                }
              }

              .pencilIcon {
                cursor: pointer;
                color: #888;

                &:hover {
                  color: #000;
                }
              }
            }
          }

          .updateButton {
            background-color: $base-green;
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            margin-top: 3rem;
            font-weight: 500;
            -webkit-box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.73);
            -moz-box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.73);
            box-shadow: 3px 4px 0px 0px rgba(0, 0, 0, 0.9);

            &:hover {
              /* Optional hover styles */
            }
          }

          @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
          }
        }
      }

      .passwordChangeSection {
        h3 {
          font-size: 16px;
          color: $base-pink;
          margin-bottom: 16px;
        }

        .pass_input {
          display: flex;
          flex-direction: row;
          gap: 1rem;

          .label {
            font-size: 14px;
            color: #888;
            margin-bottom: 4px;
            display: block;
          }

          .editable {
            display: flex;
            align-items: center;
            gap: 1rem;
            border: none;
            outline: none;
            background: none;
            font-size: 14px;
            color: #333;
            padding: 0 2px;
            transition: border-bottom 0.3s ease;
            border-bottom: 1px solid transparent; // Default state without underline

            &.underline {
              border-bottom: 1px solid #000; // Underline effect when editing
            }

            .editableInput {
              border: none;
              outline: none;
              background: none;
              flex-grow: 0; // Ensures input takes up maximum available space
            }

            .pencilIcon {
              cursor: pointer;
              color: #888;

              &:hover {
                color: #000;
              }
            }
          }
        }
        .changePasswordContainer {
          display: "flex";
          gap: "1rem";
          margin-bottom: "2rem";

          @media (max-width: 768px) {
            display: "flex";
            flex-direction: "column";
          }
        }
        .saveButton {
          background-color: $base-green;
          color: white;
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-weight: 500;
          -webkit-box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.73);
          -moz-box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.73);
          box-shadow: 3px 4px 0px 0px rgba(0, 0, 0, 0.9);
        }
      }

      .paymentContainer {
        margin-top: 1rem;

        .heading {
          color: $base-pink;
          border-bottom: 1px solid lightgray;
        }
        .table_container {
          width: 100%;
          overflow-x: auto;
          margin-top: 20px;

          .paymentTable {
            width: 99%;
            border-collapse: collapse;
            border-radius: 5px;

            th {
              border: 1px solid #edebeb;
              border-radius: 5px;
              text-align: center;
              padding: 8px;
            }
            td {
              border: 1px solid #edebeb;
              font-size: 14px;
              text-align: center;
              padding: 8px;
            }

            th {
              background-color: #f2f2f299;
              font-weight: 500;
            }

            tr:nth-child(even) {
              background-color: #f9f9f9;
            }

            a {
              display: flex;
              align-items: center;
              gap: 10px;
              justify-content: center;
              color: #2e2e2e;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

/* Responsive styles for Tablets and Mobile */
@media (max-width: 768px) {
  .profileOverlay {
    .profileContainer {
      min-width: 90%;
      max-height: 90%;
      padding: 0 16px 16px;
    }
  }
  .profileContainer {
    .header {
      flex-direction: column;
      gap: 1rem;
    }
    .profileContent {
      .top_container {
        grid-template-columns: 1fr;
        gap: 1rem;
      }
      .personalInfo,
      .passwordChangeSection,
      .paymentContainer {
        padding: 0 8px;
      }
    }
  }
}

@media (max-width: 480px) {
  .profileOverlay {
    .profileContainer {
      min-width: 95%;
      padding: 0 8px 16px;
    }
  }
  .profileContainer {
    .header {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
      .left_side {
        h2 {
          font-size: 20px;
        }
      }
    }
    .profileContent {
      .top_container {
        /* Change to flex column for mobile */
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
      .userInfo {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 1rem 0;
        img {
          margin-right: 0;
          margin-bottom: 8px;
        }
      }
      .subscribedPlanInfo {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 1rem 0;
        .left_side {
          margin-right: 0;
        }
        .right_side {
          flex-direction: row;
          gap: 10px;
        }
      }
      .personalInfo {
        .infoGrid {
          /* Stack all input fields in a column */
          grid-template-columns: 1fr;
          gap: 8px;
        }
      }
    }
  }
}
