@import "../../index.scss";

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modalContent {
    background: white;
    padding: 0px 0 20px 0px;
    border-radius: 8px;
    width: 60%;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 16px;
      -webkit-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.253);
      -moz-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.161);
      box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.144);

      .left {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 1rem;
      }

      .modalClose {
        // position: absolute;
        // top: 10px;
        // right: 10px;
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .chatSection {
      // border: 1px solid $base-pink;
      padding: 1rem;
    }

    .shareSection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 70%;

      p {
        color: rgb(144, 144, 144);
      }
      .link_btn {
        display: flex;
        align-items: center;
        background: none;
        border: none;
        cursor: pointer;
        gap: 10px;
        color: $base-pink;
        border: 1px solid $base-pink;
        padding: 8px 12px;
        border-radius: 6px;
        font-weight: 500;
      }
    }

    @media (max-width: 768px) {
      width: 90%;
    }
  }
}
