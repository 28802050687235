@import "../../index.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  margin-bottom: 40px;
  overflow: hidden;

  .hero_banner {
    position: relative;
    width: 100%;
    background-image: url("../../assets/banner_image.svg");
    height: 100%;
    min-height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;

    .text_area {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      text-align: left;
      padding: 20px;
      width: 55%;
      height: 100%;
      min-height: 400px;
      margin-left: 5rem;

      .banner_data {
        text-decoration: underline;
        text-decoration-color: $base-green;
        text-decoration-thickness: 10px;
      }

      .naturalWay {
        white-space: nowrap;
        color: white;
        padding: 0 15px;
        background-color: $base-pink;
        box-shadow: 5px 7px 0px 0px rgb(0, 0, 0);
      }

      h1 {
        margin-top: 2rem;
        font-size: 2.5rem;
        color: rgb(0, 0, 0);
      }

      p {
        margin: 20px 0;
        font-size: 1.25rem;
        color: rgb(0, 0, 0);
      }

      .button_container {
        display: flex;

        .cta_button {
          margin: 10px;
          padding: 10px 20px;
          font-size: 1rem;
          color: white;
          border-radius: 5px;
          border: 1px solid black;
          display: flex;
          align-items: center;
          gap: 1rem;
          font-weight: 600;
          box-shadow: 3px 5px 0px 0px rgb(0, 0, 0);
        }
        .getStarted {
          background-color: $base-pink;
        }
        .watchDemo {
          background-color: $base-green;
        }
      }
    }

    @media (max-width: 768px) {
      background-image: none;
    }
  }

  .company_images_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .feature_section_container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    text-align: center;

    .headingContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .headingContainer_heading {
      font-weight: 600;
      font-size: 25px;
    }
    .headingContainer_subheading {
      color: $base-pink;
      font-weight: 400;
      font-size: 26px;
    }

    .feature_boxes {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: repeat(3, 1fr);
      border-radius: 6px;
      gap: 1rem;
      padding: 2rem;

      .featurebox_contaniner {
        padding: 15px;
        display: flex;
        flex-direction: column;
        border-radius: 6px;
        gap: 1rem;
        text-align: left;
      }
    }

    .helpmeSection {
      margin-top: 1rem;
      margin-bottom: 1rem;
      width: 100vw;
      position: relative;

      .offer2 {
        font-weight: 600;
      }

      .factor_box {
        background-color: $base-pink;
        color: white;
        padding: 5px 16px;
        box-shadow: 5px 5px 0px 0px rgb(0, 0, 0);
      }

      .help_me {
        @media (max-width: 768px) {
          width: 90%;
        }
      }
    }

    .ratings_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      width: 100vw;
      position: relative;

      .rating_text_container {
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: 1rem;

        .rating {
          display: flex;
          align-items: center;
          gap: 0.6rem;
          color: $base-pink;
        }
      }
      .rating_img {
        position: absolute;
        bottom: -50%;
        right: -15%;

        @media (max-width: 768px) {
          display: none;
        }
      }
      .gpt_green {
        position: absolute;
        bottom: 70%;
        left: 10%;
      }
      .gpt_pink {
        position: absolute;
        bottom: 0;
        right: 25%;
        transform: scale(0.7);

        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    // Offering Section
    .offering_container {
      width: 100%;
      margin-bottom: 4rem;

      .archer_container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        .archer_element1 {
          padding: 1rem 0 1rem 2rem;
          grid-column-start: 1;
          grid-column-end: 8;
          margin-bottom: 0.7rem;
        }

        .archer_element2 {
          padding: 1rem 0 1rem 2rem;
          grid-column-start: 8;
          grid-column-end: 13;
          margin-bottom: 0.7rem;
          margin-left: 0.7rem;
        }

        .archer_element3 {
          padding: 1rem 0 1rem 2rem;
          grid-column-start: 1;
          grid-column-end: 7;
          margin-right: 0.7rem;
        }

        .archer_element4 {
          padding: 1rem 0 1rem 2rem;
          grid-column-start: 7;
          grid-column-end: 13;
        }

        .archer_element {
          .larger_div {
            display: flex;
            flex-direction: column;
            position: relative;
            text-align: left;
            margin-top: 5rem;

            .imagePositionLeft {
              position: absolute;
              bottom: 0;
              left: 2em;
              margin-bottom: 1px;

              @media (max-width: 768px) {
                display: none;
              }
            }
            .text_container {
              border: 1px solid $base-pink;
              background-color: #f3f9fb;
              padding: 2rem 0;
              border-radius: 10px;

              p {
                width: 70%;
                font-size: 1rem;
                color: #333;
              }
            }
            .paddingLeft {
              font-size: 1.25rem;
              padding-left: 300px;
            }
            .paddingRight {
              font-size: 1.25rem;
              margin-bottom: 1.5rem;
              padding-right: 300px;
              text-align: left;
              padding-left: 2rem;
            }

            .imagePositionRight {
              position: absolute;
              bottom: 0;
              right: 2em;
              margin-bottom: 1px;

              @media (max-width: 768px) {
                display: none;
              }
            }

            h3 {
              color: $base-pink;
            }
            .text_containerRight {
              border: 1px solid $base-pink;
              padding: 2rem 0;
              border-radius: 10px;
              background-color: #f3f9fb;
              padding-left: 2rem;

              p {
                font-size: 1rem;
                color: #333;
                width: 70%;
              }
            }
          }
        }

        // Pink dashed line and its dot – remove on screens below 768px
        .dotted_line1 {
          grid-column-start: 1;
          grid-column-end: 7;
          height: 60px;
          border-right: 3px dashed #ae407a;
          position: relative;

          @media (max-width: 768px) {
            display: none;
          }

          .dot1 {
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #ae407a;
            top: -6px;
            right: -6px;

            @media (max-width: 768px) {
              display: none;
            }
          }
        }
      }

      @media (max-width: 768px) {
        .archer_container {
          display: flex;
          flex-direction: column;

          .archer_element1,
          .archer_element2,
          .archer_element3,
          .archer_element4 {
            grid-column-start: auto;
            grid-column-end: auto;
            margin: 1rem 0;
            margin-left: 0 !important;
            margin-right: 0 !important;
            padding: 1rem;
          }
        }

        .larger_div {
          margin-top: 2rem;
          .imagePositionLeft,
          .imagePositionRight {
            position: relative;
            left: auto;
            right: auto;
            bottom: auto;
            margin: 0 0 1rem 0;
          }
          .text_container,
          .text_containerRight {
            p {
              width: 100%;
            }
          }
          .paddingLeft,
          .paddingRight {
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
      }
    }

    .security_container {
      .top_container {
        .trust_center {
          background-color: $base-pink;
          font-weight: 600;
          font-size: 20px;
          color: white;
          padding: 5px 16px;
          box-shadow: 5px 5px 0px 0px rgb(0, 0, 0);
        }
      }
      .bottom_container {
        padding: 1rem;
        margin-top: 3rem;
        display: flex;
        flex-direction: row;
        gap: 5rem;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        .left {
          text-align: left;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          align-items: flex-start;

          h3 {
            color: $base-pink;
          }
          span {
            background-color: $base-pink;
            font-weight: 600;
            font-size: 18px;
            color: white;
            padding: 5px 16px;
            box-shadow: 5px 5px 0px 0px rgb(0, 0, 0);
            cursor: pointer;
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .security_right_item {
            display: flex;
            gap: 1rem;

            .top_item {
              display: flex;
              flex-direction: column;
              gap: 0.5rem;

              h4 {
                color: $base-pink;
              }
            }
          }
        }
      }
    }

    .handson_container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .top {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        h3 {
          color: $base-pink;
        }
        p {
          cursor: pointer;
          text-decoration: underline;
          width: fit-content;
        }
      }

      .bottom img {
        width: 50vw;
        max-width: 100%;
        height: auto;
      }
    }

    .testimonial_container {
      width: 100vw;
      .Testimonials {
        color: $base-pink;
      }
    }
  }

  .offering {
    border: 1px solid $base-pink;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 14px;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      text-align: left;
    }
    .right {
    }
  }

  .offering2 {
    display: flex;
    flex-direction: row;
  }
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .container {
    .hero_banner {
      .text_area {
        width: 80%;
        margin-left: 2rem;
        h1 {
          font-size: 2rem;
        }
        p {
          font-size: 1.1rem;
        }
      }
    }
    .feature_section_container {
      .feature_boxes {
        grid-template-columns: 1fr 1fr;
      }
      .headingContainer_heading {
        font-size: 1.8rem;
      }
      .headingContainer_subheading {
        font-size: 1.5rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    .hero_banner {
      .text_area {
        width: 90%;
        margin: 1rem auto;
        text-align: center;
        h1 {
          font-size: 1.8rem;
        }
        p {
          font-size: 1rem;
        }
      }
    }
    .feature_section_container {
      .feature_boxes {
        grid-template-columns: 1fr;
      }
      .headingContainer_heading {
        font-size: 1.6rem;
      }
      .headingContainer_subheading {
        font-size: 1.3rem;
      }
    }
    .offering_container {
      .archer_container {
        grid-template-columns: repeat(1, 1fr);
        .archer_element1,
        .archer_element2,
        .archer_element3,
        .archer_element4 {
          grid-column: 1 / -1;
          margin: 0.5rem 0;
          padding: 1rem;
        }
      }
    }
    .security_container {
      .bottom_container {
        flex-direction: column;
        gap: 2rem;
        .left,
        .right {
          align-items: center;
          text-align: center;
        }
      }
    }
    .handson_container {
      .bottom img {
        width: 80vw;
      }
    }
  }
}

@media (max-width: 480px) {
  .container {
    .hero_banner {
      .text_area {
        width: 95%;
        margin: auto;
        padding: 1rem;
        h1 {
          font-size: 1.6rem;
        }
        p {
          font-size: 0.95rem;
        }
        .button_container {
          flex-direction: column;
          button {
            width: 100%;
            margin: 0.5rem 0;
          }
        }
      }
    }
    .feature_section_container {
      .headingContainer_heading {
        font-size: 1.5rem;
      }
      .headingContainer_subheading {
        font-size: 1.2rem;
      }
      .feature_boxes {
        padding: 1rem;
      }
    }
    .offering_container {
      .archer_container {
        .archer_element {
          .larger_div {
            .paddingLeft,
            .paddingRight {
              padding-left: 1rem !important;
              padding-right: 1rem !important;
            }
            .text_container,
            .text_containerRight {
              p {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .security_container {
      .bottom_container {
        .left,
        .right {
          padding: 0 1rem;
        }
      }
    }
  }
}
