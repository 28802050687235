.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modalContent {
    background: white;
    padding: 0 0 20px 0;
    border-radius: 8px;
    width: 70%;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 80%;
    overflow: auto;

    // Tablet screens
    @media (max-width: 1024px) {
      width: 90%;
      max-height: 90%;
      padding: 10px;
    }

    // Mobile screens
    @media (max-width: 768px) {
      width: 95%;
      padding: 10px;
    }

    .header {
      z-index: 1;
      position: sticky;
      top: 0;
      background-color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 16px;
      -webkit-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.253);
      -moz-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.161);
      box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.144);

      .left {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 1rem;
      }

      .modalClose {
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
      }

      // For mobile, stack header items vertically
      @media (max-width: 768px) {
        overflow-x: auto;
        overflow-y: visible;
        height: 8ch;
      }
    }

    .modalBody {
      padding: 0 20px 20px 20px;

      label {
        width: 12%;
        @media (max-width: 768px) {
          width: auto;
          margin-bottom: 5px;
        }
      }

      .top {
        display: flex;
        gap: 10px;
        align-items: center;
        color: #333;
        font-size: 1.1rem;
      }

      .bottom {
        margin-left: 7px;
        padding-left: 20px;
        border-left: 1px solid black;

        p {
          display: flex;
          align-items: center;
          gap: 8px;
          font-weight: 500;
          color: rgb(71, 71, 71);
          font-size: 20px;

          @media (max-width: 768px) {
            font-size: 16px;
          }
        }

        .input_wrapper {
          display: flex;
          align-items: center;
          gap: 1rem;
          width: 170%; // Consider revisiting this value if needed

          @media (max-width: 768px) {
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }

      textarea {
        width: 100%;
        padding: 10px;
        margin-top: 10px;
        border: 1px solid #ccc;
        resize: none;

        @media (max-width: 768px) {
          padding: 8px;
        }
      }

      .buttons {
        margin-top: 20px;
        display: flex;
        align-items: center;
        gap: 1rem;

        button {
          display: flex;
          align-items: center;
          gap: 8px;
          color: black;
          background-color: white;
          border: 1px solid black;
          padding: 10px 20px;
          font-weight: 500;
          cursor: pointer;
          border-radius: 5px;

          &:hover {
            background-color: darken(#fff, 5%);
          }

          @media (max-width: 768px) {
            width: 100%;
            justify-content: center;
            padding: 8px;
          }
        }

        @media (max-width: 768px) {
          flex-direction: column;
        }
      }

      .integrations_container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .steps_container {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .step {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            &:not(:last-child) {
              border-bottom: 1px solid rgb(234, 234, 234);
            }

            .text {
              .title {
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 0.3rem;

                @media (max-width: 768px) {
                  font-size: 16px;
                }
              }
            }

            .img {
              scale: 0.7;
              z-index: 0;
            }

            .copyButton {
              cursor: pointer;
              background-color: transparent;
              border: 1px solid black;
              border-radius: 4px;
              outline: none;
              margin-left: 10px;
              font-size: 12px;
              padding: 5px 10px;

              @media (max-width: 768px) {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}

.input__Container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 1rem;
}

.personaSettings__Area {
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.label {
  width: 40%;

  @media (max-width: 768px) {
    width: auto;
  }
}
