@import "../../index.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.425);
  overflow: hidden;

  .modal_content {
    display: flex;
    background-color: white;
    width: 70%;
    height: auto;
    border-radius: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    @media (max-width: 768px) {
      border-radius: 20px;
      width: 90%;
    }
  }

  .left_side {
    flex: 1;
    background-color: $base-pink;
    padding: 1.8rem;
    position: relative;

    .container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: left;
      align-items: start;
      position: relative;
      gap: 2rem;

      .text_container {
        color: white;
      }

      .tryitout_image {
        position: absolute;
        width: 100%;
        height: auto;
        bottom: -20%;

        @media screen and (max-width: 600px) {
          bottom: -5%;
        }
        @media screen and (min-width: 601px) and (max-width: 1000px) {
          bottom: -10%;
        }
        @media screen and (min-width: 1600px) {
          width: 100%;
        }
        @media screen and (min-width: 2000px) {
          width: 80%;
        }
      }
    }
    // @media (max-width: 768px) {
    //   display: none;
    // }
  }

  .right_side {
    position: relative;
    flex: 1;
    padding: 20px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    .right_side_container {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      width: 100%;
      max-width: 500px;
      justify-content: center;
      align-items: center;

      .form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 80%;
      }

      .input_container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .input {
          border: 1px solid rgb(211, 211, 211);
          padding: 5px;
          border-radius: 5px;
          width: 100%;
          background-color: #fff;
        }

        ::-webkit-input-placeholder {
          color: grey;
        }

        input {
          width: 100%;
          border: none;
          outline: none;
          background-color: #fff;
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }

        .password_input {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .button_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;

        .login_btn {
          background-color: $base-pink;
          width: 100%;
          color: white;
          height: 40px;
          border: none;
          outline: none;
          border-radius: 5px;
        }

        .google_btn {
          background-color: rgb(229, 228, 228);
          width: 100%;
          color: black;
          height: 40px;
          border: none;
          outline: none;
          border-radius: 5px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 1rem;
        }
      }

      .signup_text {
        color: #32b4a2;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    @media (max-width: 768px) {
      width: 90%;
    }
  }

  .close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    color: #000;
  }
  .back_button {
    top: 20px;
    left: 15px;
    position: absolute;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-left: 0.5rem;
      font-size: 16px;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.error_message {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.input_container {
  position: relative;

  label {
    display: flex;
    align-items: center;
    position: relative;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    margin-left: 0.5rem;
    cursor: pointer;

    .info_icon {
      color: #555;
      transition: color 0.3s;

      &:hover {
        color: #000;
      }
    }

    .tooltiptext {
      visibility: hidden;
      width: 220px;
      background-color: #555;
      color: #fff;
      text-align: left;
      border-radius: 6px;
      padding: 8px;
      position: absolute;
      z-index: 1;
      bottom: 125%; /* Position above the icon */
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      transition: opacity 0.3s;
      font-size: 0.875rem;

      /* Arrow */
      &::after {
        content: "";
        position: absolute;
        top: 100%; /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
      }
    }

    &:hover .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  }
}

/* Responsive Styles */

/* Tablet & Mobile Devices */
@media only screen and (max-width: 1024px) {
  .modal_content {
    width: 90%;
    border-radius: 30px;
    flex-direction: row; // Stack the left and right sections vertically
  }
  .left_side,
  .right_side {
    flex: unset;
    width: 100%;
  }
  .left_side {
    padding: 1.5rem;
    .tryitout_image {
      position: relative;
      bottom: 0;
      margin-top: 1rem;
      width: 100%;
    }
  }
  .right_side {
    padding: 1.5rem;
  }
  .close {
    top: 5px;
    right: 10px;
    font-size: 24px;
  }
  .back_button {
    top: 10px;
    left: 10px;
    span {
      font-size: 14px;
    }
  }
}

/* Mobile Devices (smaller than 600px) */
@media only screen and (max-width: 600px) {
  .modal_content {
    width: 95%;
    border-radius: 20px;
  }
  .left_side {
    display: none;
  }
  .right_side {
    padding: 1rem;
  }
  .right_side_container {
    width: 90%;
  }
  .input_container {
    .input {
      padding: 8px;
    }
  }
  .button_container {
    .login_btn,
    .google_btn {
      height: 45px;
    }
  }
  .close {
    top: 5px;
    right: 5px;
    font-size: 20px;
  }
}
