@import "../../index.scss";

.container {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: 100vh;
  overflow-y: hidden;

  .right {
    display: flex;
    flex-direction: column;
    flex: 2;
    height: 100vh;

    .bottom {
      display: flex;
      flex-direction: row;
      height: 100%;
      overflow-y: auto;

      .project_files_container {
        padding: 1rem;
        width: 50%;
        border-right: 1px solid rgb(228, 226, 226);
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .top_two {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          height: 95%;
        }

        .top {
          display: flex;
          justify-content: space-between;
          gap: 2rem;

          .left {
            display: flex;
            flex-direction: column;
            height: min-content;
            gap: 0.7rem;

            h3 {
              font-weight: 500;
              font-size: 20px;
              text-transform: capitalize;
            }

            .input_container {
              border: 1px solid black;
              border-radius: 4px;
              padding: 6px 10px;
              display: flex;
              align-items: center;
              gap: 10px;
              justify-content: space-between;
              height: 40px;

              input {
                border: none;
                outline: none;
                background-color: white;
                width: 90%;
              }
            }
          }
          .right {
            display: flex;
            flex-direction: row;
            align-items: end;
            justify-content: flex-end;
            gap: 15px;
            height: 100%;

            .button {
              border-radius: 4px;
              border: 1px solid black;
              background-color: white;
              outline: none;
              display: flex;
              align-items: center;
              gap: 8px;
              height: 40px;
              padding: 6px 10px;
            }
            .upload {
              color: $base-pink;
              border: 1px solid $base-pink;
            }

            @media (max-width: 508px) {
              justify-content: flex-start;
            }
          }

          @media (max-width: 508px) {
            flex-direction: column;
          }
        }
        .bottom {
          display: flex;
          flex-direction: column;

          .documentTable {
            min-width: 100%;
            border-collapse: collapse;
            border-radius: 15px;
            max-height: 100%;
            overflow: auto;

            th {
              border-right: 1px solid #edebeb;
              border-bottom: 1px solid #edebeb;
              border-radius: 5px;
              text-align: center;
              padding: 8px;
              background-color: #f2f2f2;
              font-weight: 500;
              font-size: 14px;
              position: sticky;
              top: 0;
              z-index: 2;
            }

            th:nth-last-child(1) {
              border-right: none;
            }

            td {
              border: 1px solid #edebeb;
              border-left: none;
              font-size: 14px;
              text-align: center;
              padding: 8px;
            }

            td:nth-last-child(1) {
              border-right: none;
            }

            tr:nth-last-child(1) {
              border-bottom: none;
            }

            tbody {
              .fileName {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .left {
                  display: flex;
                  align-items: center;
                  text-align: left;
                }

                .filename {
                  width: 100%;
                  max-width: 220px;
                  overflow-x: auto;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  scrollbar-width: 0px;
                }

                .filename::-webkit-scrollbar {
                  height: 6px;
                }

                .filename::-webkit-scrollbar-thumb {
                  background-color: rgba(0, 0, 0, 0.2);
                  border-radius: 3px;
                }

                .type_container {
                  width: fit-content;
                  background-color: aquamarine;
                  padding: 5px 10px;
                  border-radius: 4px;
                  text-align: center;
                }
              }
            }
          }
        }
      }

      .chatbot_container {
        width: 50%;
        display: flex;
        flex-direction: column;
        background-color: rgb(248, 248, 248);
        height: 100%; // Ensures the chatbot container fills the screen height
        overflow: auto;
        position: relative;

        .headerContainer {
          border-bottom: 1px solid lightgray;
          font-weight: 600;
          padding-bottom: 10px;
          position: sticky;
          top: 0;
          background-color: inherit;
          padding-top: 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .heading {
            font-weight: 500;
            font-size: 24px;
            margin-left: 1rem;
          }
        }

        .bottomContainer {
          display: flex;
          flex-direction: column;
          flex: 1;
          padding: 1rem 1rem 0 1rem;

          .messagesContainer {
            flex: 1;
            flex-grow: grow;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            overflow-y: hidden;
            padding: 10px;
            overflow: auto;

            .messageList {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              max-height: 100%;
              overflow: hidden;

              .userMessage {
                align-self: flex-end;
                background-color: $base-pink;
                color: white;
                padding: 10px 1rem;
                border-radius: 15px 0px 15px 15px;
                max-width: 70%;
                margin-bottom: 10px;
                word-wrap: break-word;
              }

              .serverMessage {
                align-self: flex-start;
                background-color: #ededed;
                color: #333;
                padding: 10px 1rem 10px 1.6rem;
                border-radius: 0px 15px 15px 15px;
                max-width: 80%;
                margin-bottom: 10px;
                word-wrap: break-word;
              }

              .loader {
                align-self: center;
                color: $base-pink;
                padding: 10px;
              }
            }
          }
        }
        form {
          position: sticky;
          bottom: 0;
          width: 100%;
          .inputContainer {
            height: 50px;
            background-color: rgb(240, 240, 240);
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 6px 20px;
            position: sticky;
            bottom: 0;
            width: 100%;

            .left_side {
              display: flex;
              align-items: center;
              gap: 12px;
              flex: 1;

              input {
                border: none;
                outline: none;
                background-color: transparent;
                width: 90%;
              }
            }

            svg {
              cursor: pointer;
              color: $base-pink;
              &:hover {
                color: $base-pink;
              }
            }
          }
        }
        @media (max-width: 768px) {
          height: 100vh;
        }
      }
    }
  }
}

.loader {
  position: fixed; /* Use fixed positioning so the loader is always centered on the screen */
  top: 45%;
  left: 45%;
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid transparent;
  border-radius: 50%;
  border-right-color: #25b09b;
  animation: l15 1s infinite linear;

  &::before,
  &::after {
    content: "";
    grid-area: 1 / 1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
    animation: l15 2s infinite;
  }

  &::after {
    margin: 8px;
    animation-duration: 3s;
  }

  @keyframes l15 {
    100% {
      transform: rotate(1turn);
    }
  }
}

.textloader {
  width: 30px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #000 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modalContainer {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    width: 30%;
    height: 80%;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 10px;

    .closeButton {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 30px;
      cursor: pointer;
    }

    .drop_zone {
      width: 100%;
      border: 1px solid #bf87a5;
      background-color: #ffd5e820;
      border-radius: 10px;
      padding: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      gap: 1rem;
      cursor: pointer;
      min-height: 80%;
      margin-bottom: auto;

      .text_container {
        color: grey;

        h3 {
          font-weight: 500;
          color: #000;
        }
        p {
          font-size: 12px;
        }
        .browse {
          color: rgb(69, 32, 236);
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .delete_btn {
      outline: none;
      border: none;
      background-color: transparent;
      padding-bottom: 0;
    }

    .uploadButton {
      background-color: $base-pink;
      width: 60%;
      font-size: 18px;
      border-radius: 4px;
      border: 1px solid black;
      padding: 8px 32px;
      color: #fff;
      -webkit-box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.73);
      -moz-box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.73);
      box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.73);
      cursor: pointer;

      &:hover {
        background-color: darken($color: $base-pink, $amount: 4%);
      }
    }
  }
}

.tooltip {
  position: relative;
  margin-left: 0.5rem;
  cursor: pointer;

  .info_icon {
    color: #232323;
    transition: color 0.3s;

    &:hover {
      color: #000;
    }
  }

  .tooltiptext {
    visibility: hidden;
    text-transform: capitalize;
    width: max-content;
    background-color: #555;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 0.875rem;

    .failed {
      color: red;
    }
    .okay {
      color: green;
    }
    .queue {
      color: goldenrod;
    }

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 0;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }
  }

  &.downwards {
    .tooltiptext {
      top: 100%;
      bottom: auto;
      &::after {
        top: auto;
        bottom: 100%;
        border-color: transparent transparent #555 transparent;
      }
    }
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}

.paginationContainer {
  height: 40px;
  display: flex;
  justify-content: end;
  align-items: center;

  .pagination {
    height: 100%;
    display: flex;
    gap: 8px;

    .prevButton,
    .nextButton {
      border: none;
      outline: none;
      padding: 4px 12px;
      color: rgb(0, 0, 0);
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      gap: 6px;
      align-items: center;
      background-color: transparent;
      border: 1px solid rgb(164, 164, 164);

      &:disabled {
        cursor: not-allowed;
        color: rgb(164, 164, 164);
        background-color: rgb(230, 230, 230);
        border: 1px solid rgb(200, 200, 200);
      }
    }
    .ellipsis {
      padding: 5px;
      border: none;
      outline: none;
      border-radius: 2px;
      background-color: transparent;
      border: 1px solid rgb(164, 164, 164);
    }
    .activePage,
    .pageNumber {
      padding: 5px;
      border: none;
      outline: none;
      border-radius: 2px;
      background-color: transparent;
      border: 1px solid rgb(164, 164, 164);
    }
    .activePage {
      color: white;
      background-color: $base-pink;
      border: none;
    }
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    height: auto;
    overflow-y: auto;
  }
  .container .right {
    height: auto;
  }
  .container .right .bottom {
    flex-direction: column;
  }
  .container .right .bottom .project_files_container,
  .container .right .bottom .chatbot_container {
    width: 100%;
    border-right: none;
  }
  .container .right .bottom .project_files_container {
    border-bottom: 1px solid rgb(228, 226, 226);
  }
  .modalOverlay .modalContainer {
    width: 90%;
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container .right .bottom .project_files_container {
    width: 60%;
    border-right: 1px solid rgb(228, 226, 226);
    border-bottom: none;
  }
  .container .right .bottom .chatbot_container {
    width: 40%;
  }
  .modalOverlay .modalContainer {
    width: 50%;
  }
}
