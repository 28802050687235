@import "../../index.scss";

.container {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: 100vh;
  overflow-y: hidden;

  .right {
    display: flex;
    flex-direction: column;
    flex: 2;
    height: 100vh;

    .bottom {
      display: flex;
      flex-direction: row;
      flex: 2;
      overflow-y: auto;

      .right_side {
        flex: 2;
        display: flex;
        flex-direction: column;
        padding: 1rem 1rem 0 1rem;
        height: 100%;
        width: 100%;

        .top {
          width: 100%;

          .bottom_container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            width: 100%;
            margin-top: 1rem;

            .left_side {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 1rem;

              .input {
                display: flex;
                align-items: center;
                gap: 10px;
                border: 1px solid black;
                padding: 6px 12px;
                border-radius: 8px;

                input {
                  border: none;
                  outline: none;
                  padding: 0;
                  min-height: 32px;
                }
              }
              .dropdown {
                outline: none;
                background-color: white;
                border: 1px solid black;
                box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.9);
                border-radius: 4px;
                padding: 12px 16px;
                border-right: 16px solid transparent;
              }
            }

            .top_right_side {
              display: flex;
              width: fit-content;
              gap: 1rem;
              align-items: center;

              .create_project_button {
                display: flex;
                align-items: center;
                gap: 10px;
                width: fit-content;
                color: white;
                background-color: $base-pink;
                border-radius: 4px;
                border: 1px solid black;
                padding: 8px 16px;
                box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.9);
              }
            }
            @media (max-width: 768px) {
              align-items: center;
            }
          }
        }

        // table styles
        .tableWrapper {
          flex-grow: 1;
          overflow: hidden;

          .tableContainer {
            height: 90%;
            overflow-y: auto;
            box-sizing: border-box;
            margin-top: 1.5rem;
            width: 100%;
            overflow: auto;

            .table {
              min-width: 100%;
              border-collapse: collapse;
              border-radius: 15px;

              thead {
                position: sticky;
                top: 0;
              }

              th {
                border-right: 1px solid #edebeb;
                border-bottom: 1px solid #edebeb;
                border-radius: 5px;
                text-align: center;
                padding: 8px;
                background-color: #f2f2f2;
                font-weight: 500;
                font-size: 14px;
              }
              th:nth-last-child(1) {
                border-right: none;
              }

              td {
                border: 1px solid #edebeb;
                border-left: none;
                font-size: 14px;
                text-align: center;
                padding: 8px;
              }
              td:nth-last-child(1) {
                border-right: none;
              }

              tr:nth-last-child(1) {
                border-bottom: none;
              }

              tbody {
                text-align: center;
              }

              .actionsContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px !important;
                cursor: pointer;
              }

              .files_table_data_container {
                display: flex;
                flex-direction: column;
                gap: 5px;
                max-height: 10lh;
                overflow: auto;
                padding: 10px;

                .document_span {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;

                  .type_container {
                    width: max-content;
                    background-color: aquamarine;
                    padding: 5px 10px;
                    border-radius: 4px;
                    text-align: center;
                  }
                }
              }
            }
          }
        }

        .stats_heading {
          margin-top: 2rem;
          font-weight: 500;
          font-size: 20px;
        }
        .stats_container {
          // Use CSS Grid instead of Flexbox
          display: grid;
          // Create responsive columns that shrink/grow:
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          gap: 2rem;
          margin: 1rem;

          .stats {
            // Use Flexbox inside each card for internal layout
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid black;
            border-radius: 6px;
            padding: 16px 18px;
            box-sizing: border-box; // Ensures padding/border are included in size

            .stats_left {
              display: flex;
              flex-direction: column;

              .top {
                font-weight: 500; // Changed from font-size to font-weight if that was intended
                // Optional: truncate overflowing text
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .bottom {
                font-size: 14px;
                color: rgb(48, 48, 48);
                // Optional: truncate overflowing text
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            .stats_right {
              // Maintain a square container for the icon
              aspect-ratio: 1 / 1;
              overflow: hidden;
              padding: 10px;
              border-radius: 50%;
            }
          }
        }

        // Media query for tablets and mobile devices:
        @media (max-width: 768px) {
          .stats_container {
            // Adjust the minimum column width for smaller devices
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
          }
        }

        @media (max-width: 768px) {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }
      }
    }
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid transparent;
  border-radius: 50%;
  border-right-color: #25b09b;
  animation: l15 1s infinite linear;

  &::before,
  &::after {
    content: "";
    grid-area: 1 / 1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
    animation: l15 2s infinite;
  }

  &::after {
    margin: 8px;
    animation-duration: 3s;
  }
}

@keyframes l15 {
  100% {
    transform: rotate(1turn);
  }
}

.project_name_cell {
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60ch;
}

@media (max-width: 1024px) {
  .project_name_cell {
    max-width: 50ch;
  }
}

@media (max-width: 768px) {
  .project_name_cell {
    max-width: 40ch;
  }
}

@media (max-width: 480px) {
  .project_name_cell {
    max-width: 30ch;
  }
}

/* Responsive adjustments for tablets and mobile */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    height: auto;
    overflow-y: auto;
  }

  .right {
    height: auto;
  }

  .bottom_container {
    flex-direction: column;
    align-items: stretch;
  }

  .tableContainer {
    width: 100%;
    overflow-x: auto;
  }

  /* Adjust table header font size if needed */
  .table {
    th,
    td {
      font-size: 12px;
      padding: 6px;
    }
  }
}
