@import "../../index.scss";

.container {
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid rgb(213, 213, 213);

  .right_Side_container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;

    .subscription_container {
      display: flex;
      align-items: center;
      gap: 7px;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
}

.profile_img_wrapper {
  height: 40px;
  width: 40px;
  position: relative;
  border: 1px solid $base_pink;
  border-radius: 40px;
  cursor: pointer;
}

.profileModal {
  position: absolute;
  top: 40px; // Positioned below the profile image
  right: 0;
  background-color: white;
  border: 2px solid #f8a1d1; // Use your base pink color here
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 16px;
  z-index: 1000;
  width: 250px;
  font-family: "Arial, sans-serif";

  .top_section {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 16px;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    .name {
      font-weight: 600;
      font-size: 16px;
      margin: 0;
    }

    .plan {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      color: $base_pink;
      margin: 0;
    }

    .closeIcon {
      position: absolute;
      right: 20px;
      top: 20px;
      margin-left: auto;
      cursor: pointer;
      font-size: 18px;
      color: #666;
    }
  }

  .button_container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      padding: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #333;
      border: none;
      border-radius: 5px;
      background-color: transparent;
      cursor: pointer;
      text-align: left;

      &:hover {
        background-color: #f8f8f8;
      }
    }

    .signOut {
      color: #e03333;
    }
  }
}

.language_selector {
  position: relative;
}

.language_modal {
  position: absolute;
  top: 40px; // Positioned below the language icon
  right: 0;
  background-color: white;
  border: 2px solid #f8a1d1;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 16px;
  z-index: 1000;
  width: auto;
  font-family: "Arial, sans-serif";
}

@media (max-width: 768px) {
  .container {
    flex-direction: row;
    align-items: center;
    padding: 10px;
  }

  .right_Side_container {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    margin-top: 8px;
  }

  .profile_img_wrapper {
    height: 35px;
    width: 35px;
  }

  .profileModal {
    width: 220px;
    padding: 12px;
    top: 35px;
  }

  .language_modal {
    top: 35px;
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 8px 12px;
  }

  .container h1 {
    font-size: 18px;
  }

  .profile_img_wrapper {
    height: 30px;
    width: 30px;
  }

  .profileModal {
    width: 200px;
    padding: 10px;
  }

  .language_modal {
    padding: 10px;
  }
}
