@import "../../index.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
  margin: 0 auto;
  max-width: 1200px;

  .top_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;

    .heading {
      font-size: 2rem;
      margin: 0.5rem 0;
    }
    .subHeading {
      color: $base-pink;
      font-size: 1.1rem;
    }
  }

  .bottom_container {
    width: 100%;
    display: grid;
    place-items: center;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    column-gap: 2rem;
    row-gap: 2rem;
    padding: 2rem;
    margin: 0 3rem;
    align-items: stretch;
  }
}

// Subscription tile SCSS

.tile_container {
  padding: 1.5rem 1rem;
  border: 1px solid $base-pink;
  border-radius: 8px;
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #fff;

  .tile_top_container {
    .tile_heading_container {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      .tile_heading {
        text-transform: capitalize;
        font-weight: 500;
        font-size: 24px;
        display: inline-block;
      }
    }

    .tile_subheading {
      font-weight: 400;
      font-size: 14px;
      margin-top: 0.5rem;
    }

    .tile_price {
      font-size: 20px;
      font-weight: 500;
      margin-top: 1rem;
      display: flex;
      align-items: center;
      gap: 8px;

      .tile_permonth_text {
        font-weight: 300;
        color: grey;
        font-size: 12px;
      }
    }
    .exclusive_text {
      font-size: 12px;
      color: rgb(179, 179, 179);
    }
  }

  .tile_middle_container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 5px;
    margin: 1rem 0;

    .features {
      font-weight: 500;
      font-size: 14px;
    }
    .left_gradient {
      flex: 1;
      height: 4px;
      background-image: linear-gradient(to right, #fff, $base-pink);
    }
    .right_gradient {
      flex: 1;
      height: 4px;
      background-image: linear-gradient(to left, #fff, $base-pink);
    }
  }

  .tile_bottom_container {
    padding: 0 1rem;

    .feature_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .feature {
        font-size: 14px;
        line-height: 2;
      }
    }
  }

  .buynow_btn {
    margin-top: auto;
    background-color: $base-pink;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    color: white;
    -webkit-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.253);
    -moz-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.161);
    box-shadow: 4px 3px 0px 1px rgb(0, 0, 0);
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
}

/* ===== Responsive Styles ===== */

/* Tablet devices */
@media (max-width: 1024px) {
  .container {
    padding-top: 1rem;

    .top_container {
      .heading {
        font-size: 1.75rem;
      }
      .subHeading {
        font-size: 1rem;
      }
    }

    .bottom_container {
      padding: 1.5rem;
      margin: 0 2rem;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }

  .tile_container {
    padding: 1.25rem 0.75rem;

    .tile_top_container {
      .tile_heading_container {
        .tile_heading {
          font-size: 22px;
        }
      }
      .tile_subheading {
        font-size: 13px;
      }
      .tile_price {
        font-size: 18px;
      }
    }
    .buynow_btn {
      font-size: 16px;
      padding: 8px;
    }
  }
}

/* Mobile devices */
@media (max-width: 600px) {
  .container {
    padding-top: 1rem;

    .top_container {
      .heading {
        font-size: 1.5rem;
      }
      .subHeading {
        font-size: 0.9rem;
      }
    }

    .bottom_container {
      padding: 1rem;
      margin: 0 1rem;
      grid-template-columns: 1fr; // Stacks each tile vertically
    }
  }

  .tile_container {
    padding: 1rem 0.5rem;

    .tile_top_container {
      .tile_heading_container {
        .tile_heading {
          font-size: 20px;
        }
      }
      .tile_subheading {
        font-size: 12px;
      }
      .tile_price {
        font-size: 16px;
      }
    }

    .tile_middle_container {
      .features {
        font-size: 13px;
      }
    }

    .tile_bottom_container {
      .feature_container {
        .feature {
          font-size: 13px;
        }
      }
    }

    .buynow_btn {
      font-size: 14px;
      padding: 8px;
    }
  }
}
