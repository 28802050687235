@import "../../index.scss";

.accordionItem {
  padding-left: 2rem;
  padding-right: 2rem;
  border-bottom: 2px solid #ebeaea;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  justify-content: center;

  .arrow {
    display: grid;
    place-items: center;
    height: 25px;
    aspect-ratio: 1;
    border-radius: 50px;
  }
}

.accordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  cursor: pointer;

  .leftSide {
    display: flex;
    align-items: center;
    gap: 1rem;

    .number {
      min-width: 60px;
      color: grey;
    }

    .textContent {
      flex-grow: 1;
    }
  }

  .accordion_title {
    margin: 0;
  }
}

.accordionContent {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;

  p {
    padding: 0 1em;
    margin: 0;
  }

  &.open {
    max-height: 500px; // Adjust based on expected content
    padding: 1em 0;
  }

  .links {
    margin-top: 1rem;
    padding-left: 1em;

    .link {
      color: blue;
    }
  }
}

/* ===== Responsive Styles ===== */

/* For Tablets (max-width: 768px) */
@media (max-width: 768px) {
  .accordionItem {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: 90%;
  }

  .accordionHeader {
    padding: 0.75em;

    .leftSide {
      gap: 0.75rem;

      .number {
        min-width: 50px;
        font-size: 0.9rem;
      }

      .accordion_title {
        font-size: 1rem;
      }
    }
  }

  .accordionContent {
    p {
      padding: 0 0.75em;
    }
  }
}

/* For Mobile Devices (max-width: 480px) */
@media (max-width: 480px) {
  .accordionItem {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .accordionHeader {
    padding: 0.5em;

    .leftSide {
      flex-direction: column; /* Stack number and title vertically */
      align-items: flex-start;
      gap: 0.5rem;

      .number {
        min-width: auto;
        font-size: 0.8rem;
      }

      .accordion_title {
        font-size: 0.9rem;
      }
    }

    .arrow {
      height: 20px;
    }
  }

  .accordionContent {
    p {
      padding: 0 0.5em;
      font-size: 0.9rem;
    }

    &.open {
      padding: 0.5em 0;
    }
  }
}
