@import "../../index.scss";

.disabled {
  pointer-events: none; // Prevents any interaction
  opacity: 0.5; // Optional: dims the UI to indicate it's disabled
}

.container {
  display: flex;
  height: 100vh;
  width: 100%;

  .sidebar {
    background-color: #f4f4f4;
  }

  .main {
    display: flex;
    flex-direction: column;
    flex: 1;

    .partial_header {
      // width: 100%;
      padding: 1rem;
      background-color: #fff;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      // margin-left: 20vw;
    }

    .content {
      // flex: 1;
      // margin-left: 20vw;
      height: 100%;
      width: 50%; // Desktop width: 50%
      padding: 1rem;
      display: flex;
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
      justify-content: space-between;
      overflow-y: auto;

      // Tablet screens (between 768px and 1024px): width increased to 70%
      @media (min-width: 768px) and (max-width: 1024px) {
        width: 70%;
      }

      // Mobile screens (max-width: 768px): width increased to 90%
      @media (max-width: 768px) {
        width: 90%;
      }

      .top_container {
        display: flex;
        flex-direction: column;
        width: 80%;

        .heading {
          color: $base-pink;
          font-weight: 500;
          text-align: left;
          margin-top: 1rem;
          margin-bottom: 8px;
        }
        .name_input {
          outline: none;
          background-color: none;
          border: 1px solid $base-pink;
          border-radius: 5px;
          padding: 10px 15px;
          width: 100%;
        }

        .drop_zone {
          width: 100%;
          border: 1px solid #bf87a5;
          background-color: #ffd5e820;
          border-radius: 10px;
          padding: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
          gap: 1rem;
          cursor: pointer;

          .text_container {
            color: grey;

            h3 {
              font-weight: 500;
              color: #000;
            }
            p {
              font-size: 12px;
            }
            .browse {
              color: rgb(69, 32, 236);
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }

      .middle_container {
        width: 97%;
        max-height: 400px; // Adjust as needed
        margin: 1rem 0;
        overflow-y: auto;
        padding: 1rem;

        // Scrollbar styles for WebKit browsers
        &::-webkit-scrollbar {
          width: 4px !important;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1 !important;
        }

        &::-webkit-scrollbar-thumb {
          background: #888 !important;
          border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        // Scrollbar styles for Firefox
        scrollbar-width: thin;
        scrollbar-color: #888 #f1f1f1;

        .file_div {
          display: flex;
          flex-direction: column;
          overflow: auto;
          font-size: 12px;
          margin-bottom: 5px;

          .single_file {
            display: flex;
            align-items: center;
            width: 100%;

            // The file name <p> takes up the remaining space
            .file_name {
              flex: 1;
              white-space: normal;
              overflow-wrap: break-word;
              word-break: break-all;
              margin: 0;
            }

            // Container for file type and delete button
            > div {
              display: flex;
              align-items: center;
              gap: 10px;
              flex: 0 0 auto;
            }

            .file_type {
              /* Use min-width so that it only takes the space needed */
              min-width: 40px;
              width: fit-content;
              background-color: aquamarine;
              padding: 5px 10px;
              border-radius: 4px;
              text-align: center;
            }

            .delete_btn {
              outline: none;
              border: none;
              background-color: transparent;
              padding-bottom: 0;
            }
          }
        }

        .existing_files_container {
          p {
          }
          .single_file {
            .file_type {
              min-width: 40px;
              width: fit-content;
              background-color: aquamarine;
              padding: 5px 10px;
              border-radius: 4px;
              text-align: center;
            }
          }
          @media (max-width: 500px) {
            width: 90%;
          }
        }
      }

      .submit_container {
        position: relative;
        margin-bottom: 1rem;
        display: flex;
        gap: 2rem;
        align-items: center;

        .submit_btn {
          background-color: white;
          font-size: 18px;
          border-radius: 4px;
          border: 1px solid black;
          // background-color: $base-pink;
          padding: 8px 32px;
          color: #000;
          -webkit-box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.73);
          -moz-box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.73);
          box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.73);
          cursor: pointer;

          &:hover {
            background-color: darken($color: white, $amount: 4%);
          }
          &:disabled {
            cursor: not-allowed;
            opacity: 0.6;
          }
        }
        .loader {
          position: absolute;
          right: -50px;
          width: 25px;
          aspect-ratio: 1;
          display: grid;
          border: 2px solid transparent;
          border-radius: 50%;
          border-right-color: #25b09b;
          animation: l15 1s infinite linear;

          &::before,
          &::after {
            content: "";
            grid-area: 1 / 1;
            margin: 2px;
            border: inherit;
            border-radius: 50%;
            animation: l15 2s infinite;
          }

          &::after {
            margin: 8px;
            animation-duration: 3s;
          }
        }

        @keyframes l15 {
          100% {
            transform: rotate(1turn);
          }
        }
      }
    }
  }
}

.file_div {
  display: flex;
  flex-direction: column !important;
  overflow: auto;
}
